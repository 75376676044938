import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => { 
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };
  
  return (
  <div className="not-found-container">
    <h2 className="not-found-title">404 - Not Found</h2>
    <p className="not-found-text">Oops! The page you’re looking for doesn’t exist.</p>
    <button 
            onClick={handleHome}>Home</button>
  </div>
);
};

export default NotFound;
