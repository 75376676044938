import '../App.css';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';
import React, { useEffect, useState } from 'react';
import { storage, ref, getDownloadURL } from '../firebase';


function ChatAppPart1() {
  
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    // Fetch the download URL from Firebase storage
    const fetchDownloadURL = async () => {
      const fileRef = ref(storage, 'ChatAppPart1.zip');
      try {
        const url = await getDownloadURL(fileRef);
        setDownloadURL(url);
      } catch (error) {
        console.error('Error fetching download URL:', error);
      }
    };

    fetchDownloadURL();
  }, []);

  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };

  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>Chat App with Firebase Database</h1>

          <div className='project-body-display-2'>
              <div>
                <div className='project-body-display-buttons'>
                
                {downloadURL ? (
                  <a className="download-button" href={downloadURL} download="ChatAppPart1.zip">
                    Download Project
                  </a>
                ) : (
                  <button disabled className="download-button">
                    Loading...
                  </button>
                )}

                  <button className="open-channel-button" onClick={handleChannelClick}>YouTube Channel</button>

                </div>
              </div>

              <div>
                <VideoPlayer className="video-player" videoId="xEiV45DOPxE" />
              </div>
          </div>



        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ChatAppPart1;
