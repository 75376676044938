import '../App.css';
import React, { useEffect, useState } from 'react';
import { storage, ref, getDownloadURL } from '../firebase';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import VideoPlayer from '../pages/VideoPlayer';

function SwipeRefreshLayoutPart1() {
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    // Fetch the download URL from Firebase storage
    const fetchDownloadURL = async () => {
      const fileRef = ref(storage, 'RefreshLayout.zip');
      try {
        const url = await getDownloadURL(fileRef);
        setDownloadURL(url);
      } catch (error) {
        console.error('Error fetching download URL:', error);
      }
    };

    fetchDownloadURL();
  }, []);

  const handleChannelClick = () => {
    window.open('https://www.youtube.com/@kz_Beta', '_blank');
  };
  

  return (
    <div className="App">
      <Header/>
      <div className='project-body-background'>
        <div className='project-body-display'>

          <h1>SwipeRefreshLayout - Part 1
Android Studio</h1>

          <div className='project-body-display-2'>
              <div>
                <div className='project-body-display-buttons'>
                
                {downloadURL ? (
                  <a className="download-button" href={downloadURL} download="RefreshLayout.zip">
                    Download Project
                  </a>
                ) : (
                  <button disabled className="download-button">
                    Loading...
                  </button>
                )}
                  
                
                <button className="open-channel-button" onClick={handleChannelClick}>YouTube Channel</button>

                </div>
              </div>

              <div>
                <VideoPlayer className="video-player" videoId="OBhisgXToYk" />
              </div>
          </div>

          <div className='implementation-parent'>
            <h3>Implementation</h3>

            <code>
              implementation 'androidx.swiperefreshlayout:swiperefreshlayout:1.1.0'
            </code>
          </div>

          <div className="more-info-project">
  <h3>More Information</h3>
  <p>
    Some of the common methods that developers should know when working with SwipeRefreshLayout:
  </p>
  <ul>
    <li>
      <strong>setOnRefreshListener(OnRefreshListener listener):</strong> This method sets the listener to be invoked when a refresh is triggered by the user's swipe gesture.
    </li>
    <br />
    <li>
      <strong>setRefreshing(boolean refreshing):</strong> This method sets whether the SwipeRefreshLayout should be displaying the refreshing progress indicator.
      <br />
      When set to true, it displays the progress indicator, indicating that a refresh is in progress.
      <br />
      When set to false, it hides the progress indicator, indicating that the refresh is complete.
    </li>
    <br />
    <li>
      <strong>isRefreshing():</strong> This method returns a boolean value indicating whether the SwipeRefreshLayout is currently in the process of refreshing.
    </li>
    <br />
    <li>
      <strong>setColorSchemeColors(int... colors):</strong> This method sets the colors used in the progress animation. The animation smoothly transitions between these colors as the user swipes and triggers a refresh.
    </li>
    <br />
    <li>
      <strong>setEnabled(boolean enabled):</strong> This method enables or disables the swipe-to-refresh gesture.
      <br />
      When disabled, the SwipeRefreshLayout does not respond to swipe gestures, effectively preventing the user from triggering a refresh.
    </li>
    <br />
    <li>
      <strong>setDistanceToTriggerSync(int distance):</strong> This method sets the distance in pixels that the user must swipe before a refresh is triggered. By default, this distance is set to a reasonable value, but developers can customize it if needed.
    </li>
    <br />
    <li>
      <strong>setProgressBackgroundColorSchemeColor(int color):</strong> This method sets the background color of the progress spinner/indicator.
    </li>
    <br />
    <li>
      <strong>setProgressViewOffset(boolean scale, int start, int end):</strong> This method sets the distance in pixels that the progress indicator should appear from the top of the SwipeRefreshLayout.
      <br />
      The scale parameter determines whether the start and end offset values should be scaled based on the layout's content height.
    </li>
  </ul>
</div>


        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default SwipeRefreshLayoutPart1;
