import './App.css';
import React, { useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound'

import BeometricAuthFingerprint from './projects/BeometricAuthFingerprint';
import CalculatorPart1 from './projects/CalculatorPart1';
import FlexboxLayoutPart1 from './projects/FlexboxLayoutPart1';
import FullScreenDialog from './projects/FullScreenDialog';
import GenerateQrCode from './projects/GenerateQrCode';
import ImageSliderPart1 from './projects/ImageSliderPart1';
import ImageSliderPart2 from './projects/ImageSliderPart2';
import MusicPlayerPart1 from './projects/MusicPlayerPart1';
import QrScannerPart1 from './projects/QrScannerPart1';
import SelectingImagesFromDevice from './projects/SelectingImagesFromDevice';
import SwipeRefreshLayoutPart1 from './projects/SwipeRefreshLayoutPart1';
import TabLayout from './projects/TabLayout';
import ChatAppPart1 from './projects/ChatAppPart1'

function ProjectsPath() {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      navigate('/register');
    }
  }, [navigate]);

  return (
    <div>
      <Routes>
        <Route path="swipe-refresh-layout-part-1" element={<SwipeRefreshLayoutPart1 />} />
        <Route path="selecting-images-from-device" element={<SelectingImagesFromDevice />} />
        <Route path="qr-scanner-part-1" element={<QrScannerPart1 />} />
        <Route path="music-player-part-1" element={<MusicPlayerPart1 />} />
        <Route path="image-slider-part-2" element={<ImageSliderPart2 />} />
        <Route path="image-slider-part-1" element={<ImageSliderPart1 />} />
        <Route path="generate-qr-code" element={<GenerateQrCode />} />
        <Route path="full-screen-dialog" element={<FullScreenDialog />} />
        <Route path="flexbox-layout-part-1" element={<FlexboxLayoutPart1 />} />
        <Route path="beometric-auth-fingerprint" element={<BeometricAuthFingerprint />} />
        <Route path="calculator-part-1" element={<CalculatorPart1 />} />
        <Route path="tab-layout" element={<TabLayout />} />
        <Route path="chat-app-part-1" element={<ChatAppPart1 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default ProjectsPath;
