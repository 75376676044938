import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { ref, set } from 'firebase/database';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../Auth.css"

function hashEmail(email) {
    return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
}

const Register = () => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
      const auth = localStorage.getItem('auth');
      if (!auth) {
      }else{
        navigate('/');
      }
    }, []);

  const handleRegister = async () => {
    const otpCode = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedOtp(otpCode);

    try {
      await axios.post('https://us-central1-extent-protocol-a.cloudfunctions.net/sendEmail', {
        to: email,
        subject: 'Register',
        text: `OTP: ${otpCode}`,
      });

      toast.success('OTP sent to your email successfully!');
      setOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Try again.');
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleOtpVerification = async () => {
    const hashedEmail = hashEmail(email);
    if (otp === generatedOtp) {
      try {
        await set(ref(db, `users/${hashedEmail}`), {
          fullname,
          email,
          password,
        });
        toast.success('Registration successful!');
        navigate('/login');
      } catch (error) {
        console.error('Error saving user data:', error);
        toast.error('Failed to save user. Please try again.');
      }
    } else {
        toast.error('Invalid OTP. Please try again.');
    }
  };

  return (
    <div className="register-main-parent">
      <h1 className="register-main-header">Register</h1>
      <div className="register-body">
        {!otpSent ? (
          <>
            <input
              type="text"
              placeholder="Full Name"
              value={fullname}
              className="register-input"
              onChange={(e) => setFullname(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              className="register-input"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              className="register-input"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="register-button" onClick={handleRegister}>Register</button>
            <button className="register-secondary-button" onClick={handleLogin}>Login</button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              className="register-input"
              onChange={(e) => setOtp(e.target.value)}
            />
            <button className="register-button" onClick={handleOtpVerification}>Verify OTP</button>
          </>
        )}
      </div>

        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
    </div>
  );
};

export default Register;
