import './App.css';
import { React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Login from './pages/Login';
import Register from './pages/Register';
import Verify from './0lJXeUDnHdtyfHfBGMFr8Sz8Et4FjJhVgLh2mP4mXkoDZ1rFgymIsYeTGCopttpb'
import ProjectsPath from './ProjectsPath';
import PublishedApps from './pages/PublishedApps';
import AttackAndWarOffline from './published/AttackAndWarOffline';
import MyBaidibek from './published/MyBaidibek';
import NotFound from './pages/NotFound';

import { db } from './firebase';
import { ref, set } from 'firebase/database';



function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("publicId");
    console.log(id);
  }, []);

  useEffect(() => {
    const auth = localStorage.getItem('id') || `ID${Math.floor(100000 + Math.random() * 900000)}`;
    localStorage.setItem('id', auth);
    const email = localStorage.getItem('auth');


    const now = Date.now();

    const date = new Intl.DateTimeFormat('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(now).replace(',', '');

    const saveUserData = async () => {
      try {
        await set(ref(db, `active/${auth}`), { auth, date, email });
      } catch (error) {
        console.error('Error saving user data:', error);
      }
    };

    saveUserData();
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/published-apps" element={<PublishedApps />} />
          


          
          <Route path="/projects/*" element={<ProjectsPath />} />

          <Route path="/attack-and-war-offline" element={<AttackAndWarOffline />} />
          <Route path="/my-baidibek" element={<MyBaidibek />} />


          <Route path="/.well-known/acme-challenge/0lJXeUDnHdtyfHfBGMFr8Sz8Et4FjJhVgLh2mP4mXkoDZ1rFgymIsYeTGCopttpb" element={<Verify />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

