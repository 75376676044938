import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/database"; 

const getDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0'); 
  const month = String(today.getMonth() + 1).padStart(2, '0'); 
  const year = today.getFullYear();
  const back = `${day}:${month}:${year}`
  console.log(back);
  return back;
};

const Header = () => {
  const [deviceId, setDeviceId] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  useEffect(() => {

    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        const data = await response.json();
        setIpAddress(data.ip);
        saveDeviceInfo(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    

    const saveDeviceInfo = async (ipAddress) => {
      try {

        if(!firebase.apps.length){
          firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
          })
        }

        const database = firebase.database();
        const currentDate = getDate();

        const deviceRef = database.ref(`${currentDate}/devices`).push();
        setDeviceId(deviceRef.key);

        const deviceInfo = {
          userAgent: navigator.userAgent,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          networkInfo: {
            ipAddress: ipAddress, 
            connectionType: navigator.connection.effectiveType
          },
          screenInfo: {
            pixelDensity: window.devicePixelRatio,
            colorDepth: window.screen.colorDepth,
            aspectRatio: window.screen.width / window.screen.height
          },
          userPreferences: {
            language: navigator.language
          },
          referrerInfo: document.referrer,
          // Additional information
          geolocation: {}, // Will be filled dynamically
          browserInfo: {
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
            cookiesEnabled: navigator.cookieEnabled,
            userAgent: navigator.userAgent
          },
          sessionInfo: {}, // Will be filled dynamically
          interactions: [], // Will be filled dynamically
          pageInfo: {}, // Will be filled dynamically
          deviceType: {
            isMobile: /Mobi/.test(navigator.userAgent),
            isTablet: /Tablet/.test(navigator.userAgent),
            isDesktop: /Desktop/.test(navigator.userAgent)
          },
          errors: [] // Will be filled dynamically
        };
        

        await deviceRef.set(deviceInfo);

        console.log('Device information saved successfully');
        setDeviceId(deviceRef.key);
      } catch (error) {
        console.error('Error saving device information:', error);
      }
    };

    fetchIpAddress();
  }, []); 

  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo">
          <h1 onClick={handleButtonClick}>kz Beta Cloud</h1>
        </div>
        <ul className="nav-links">
          <li><a href="/">Home</a></li>
          <li><a href="/projects">Projects</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
