// firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBD3QNM9gktNs3Qfsyag6DCw4E78LIEP54",
    authDomain: "kz-beta-cloud.firebaseapp.com",
    databaseURL: "https://kz-beta-cloud-default-rtdb.firebaseio.com",
    projectId: "kz-beta-cloud",
    storageBucket: "kz-beta-cloud.firebasestorage.app",
    messagingSenderId: "257910054759",
    appId: "1:257910054759:web:197c24d3d7461a9cd7d9c2",
    measurementId: "G-5TF1M2R5XD"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const db = getDatabase(app);

export { storage, ref, getDownloadURL, db };
